@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body {
  font-family: "Roboto", sans-serif;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
}

/*orokorrak*/
label {
  font-size: 13px;
  margin-bottom: 0;
}

.card-header {
  background-color: initial;
  border-bottom-width: 0;
  padding-bottom: 3px;
}

.k-button-group .k-button:active, .k-button-group .k-button.k-state-active, .k-button-group .k-button.k-state-selected, .k-button-group > input[type=radio]:checked + .k-button, .k-button-group > input[type=checkbox]:checked + .k-button {
  border-color: #00a8ff;
  color: #ffffff;
  background-color: #00a8ff;
  background-image: linear-gradient(rgba(255, 138, 88, 0.2), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

.k-upload .k-dropzone {
  background-color: transparent;
  padding: 3px;
}

.card-header + .card-body {
  padding: 0 1rem 0.5rem;
}

.k-textbox.form-control, kendo-textbox.k-textbox .k-input {
  height: calc(1.8rem + 1px) !important;
  padding: 0.5rem 0.75rem !important;
}

.btn {
  /*padding: 4px 8px;
  font-size: 14px;*/
}

.btn-success {
  background-color: #8bc34a;
  border-color: #8bc34a;
}

.btn-primary {
  background-color: #00a8ff;
  border-color: #00a8ff;
}

.btn-danger {
  background-color: #f16a72;
  border-color: #f16a72;
}

.btn-pdf {
  background-color: #c52d12;
  border-color: #c52d12;
  color: #ffffff;
}

.btn-excel {
  background-color: #10d143;
  border-color: #10d143;
  color: #ffffff;
}

::selection {
  background: #a1cff7;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #a1cff7;
  /* Gecko Browsers */
}

a, a:hover {
  color: #00a8ff;
}

h3 {
  font-size: 15px;
  color: #00a8ff;
  overflow: hidden;
  text-transform: uppercase;
  padding: 0 0 5px 0;
  border-bottom: solid 1px #00a8ff;
  font-family: "Roboto", sans-serif;
}

textarea.k-textarea {
  width: 100% !important;
}

.modal-header .close {
  padding: 5px;
  outline: 0;
}

.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td {
  background-color: rgba(34, 196, 196, 0.45);
}

.k-checkbox:checked {
  border-color: #00a8ff;
  background-color: #00a8ff;
}

.k-checkbox:indeterminate, .k-checkbox.k-state-indeterminate {
  color: #00a8ff;
}

kendo-textbox.k-textbox {
  width: 100%;
  height: auto;
}

kendo-textbox.k-textbox .k-input {
  padding: 0.5rem 0.75rem;
  height: 2.25rem;
}

.k-button,
.k-button:hover {
  background-image: none;
}

.k-numerictextbox {
  width: auto !important;
}

.form-group {
  margin-bottom: 0.2rem;
}

.k-button-group .k-button:hover,
.k-button-group .k-button.k-state-hover,
.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active {
  z-index: 0;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: #00a8ff;
}

.k-list .k-item.k-state-selected:hover,
.k-list-optionlabel.k-state-selected:hover {
  background-color: #00a8ff;
  opacity: 0.9;
}

.k-calendar .k-state-selected .k-link {
  background-color: #00a8ff;
}

.k-calendar .k-state-selected:hover .k-link,
.k-calendar .k-state-selected.k-state-hover .k-link {
  background-color: #00a8ff;
  opacity: 0.9;
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: #00a8ff;
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-state-hover {
  color: #00a8ff;
}

.k-widget.form-control .k-input {
  height: calc(1.6rem + 1px);
  padding: 0.5rem 0.75rem;
}

.form-control {
  height: calc(1.8rem + 1px);
  font-size: 14px;
}

.k-dialog-buttongroup {
  justify-content: center;
}

.k-button.k-upload-button {
  padding: 0px 6px;
}

.k-textarea {
  width: 100%;
}

.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  background-image: none;
  background: transparent;
}

.k-dropdown, .k-dropdowntree {
  width: 100%;
}

.k-grid tbody td {
  white-space: nowrap;
  line-height: 10px;
  padding: 8px 12px;
}

.k-grid .k-grid-content td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

alert {
  position: fixed;
  top: 50px;
  z-index: 10;
  left: 0px;
  right: 0px;
}

.registro {
  max-width: 500px;
  margin: 0 auto !important;
}

input.k-textbox {
  width: 100%;
  height: 31px;
}

/*orokorrak amaiera*/
/*login*/
.card.login .card-footer button {
  background-color: transparent;
  border-width: 0;
  color: #007bff;
}

.login-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: #21b8db;
  background: linear-gradient(0deg, #21b8db 0%, #2274c3 100%);
  background-image: url("https://www.realsociedad.eus/Content/img/club/facilities/Cesped-de-Anoeta-4.jpg");
  /*background-image: url("https://c.pxhere.com/photos/e3/3f/the_ball_stadion_football_the_pitch_grass_game_sport_match-1089676.jpg!d");*/
  background-size: cover;
}

.card.login {
  width: 275px;
  margin: 125px auto 0;
  background-color: rgba(241, 241, 241, 0.39);
  backdrop-filter: blur(4px);
}

.card.login > input,
.card.login > button {
  text-align: center;
}

.esconder-margen {
  background-color: red;
  margin: 0 !important;
}

/*login amaiera*/
/*botoiak*/
.btn {
  border-radius: 0;
}

.btn-editar {
  background-color: #36a884;
  color: #ffffff;
}

.btn-nuevo {
  background-color: #8bc34a;
  color: #ffffff;
}

.btn-eliminar {
  background-color: #f16a72;
  color: #ffffff;
}

.btn-copiar {
  background-color: #22c4c4;
  color: #ffffff;
}

/*botoiak amaiera*/
/*scroll bereziak*/
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  height: 8px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cdcdcd;
}

*::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #253341;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*scroll bereziak bukaera*/
/*tabs*/
.k-tabstrip-items .k-item,
a, a:hover {
  color: #00a8ff;
}

/*tabs amaiera*/
/*switch*/
.k-switch-on:hover .k-switch-container, .k-switch-on.k-state-hover .k-switch-container {
  color: #ffffff;
  background-color: #00a8ff;
}

.k-switch-on .k-switch-container {
  color: #ffffff;
  background-color: #00a8ff;
}

.k-switch-container {
  width: 4em;
}

.k-switch-handle {
  width: 2em;
  height: 2em;
}

.k-switch {
  border-radius: 4em;
  width: 4em;
  margin-right: 5px;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 2em);
}

.k-switch-label-on, .k-switch-label-off {
  max-width: 100%;
}

.k-switch-label-on {
  left: 3px;
}

.k-switch-label-off {
  right: 3px;
}

/*switch amaiera*/
.barratop {
  transition: 0.3s ease all;
  background-color: #253341;
  left: 60px;
  border-radius: 0;
  position: fixed;
  top: 0;
  right: 0;
  height: 56px;
  padding: 0;
  z-index: 5;
}

.barratop h2 {
  margin: 0 0 0 20px;
  color: #f7f7f7;
  float: left;
  font-size: 20px;
}

/*MENU LATERAL*/
.barramenu {
  background-color: #2a3f54;
  width: 60px;
  padding: 0 0 40px 0;
  flex-flow: column;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  z-index: 5;
}

.barramenu .logotipoa {
  margin: 8px 0 10px 0;
  height: 40px;
  width: 54px;
  float: none;
  background-position: 10px;
  background-repeat: no-repeat;
  /*  transition: 0.3s ease all;
    background-size: cover;*/
  background-image: url(images/logo.png);
}

.barramenu .nav-item .nav-link {
  color: #CAD3DD;
}

.barramenu .nav-item.link-active a.nav-link {
  color: #00a8ff;
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu h3 {
  display: none;
  overflow: hidden;
  clear: both;
  background-color: transparent;
  color: #fff;
  margin: 15px 12px 15px 11px;
  text-transform: inherit;
  color: #CAD3DD;
  font-weight: 300;
}

.barramenu a {
  visibility: hidden;
  width: 230px;
  color: #CAD3DD;
  font-size: 13px;
}

.barramenu .beheko-minimenu a.conf:before, .barramenu > .nav > li > a:before {
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  display: inline-block;
  font-family: Font Awesome\ 5 Free;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  font-size: 20px !important;
  margin-right: 15px;
  transition: all 0.5s;
}

.barramenu.menu-zabalduta ul.beheko-minimenu, .barramenu:hover ul.beheko-minimenu, ul.beheko-minimenu li {
  width: 230px;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #00a8ff;
  color: #00a8ff !important;
}

ul.nav {
  margin: 47px 0 0 0;
  width: 100%;
}

ul.nav li.nav-item {
  width: 100%;
}

.barramenu > .nav > li > a.inicio-nav:before {
  content: "";
}

.barramenu > .nav > li > a.encuestas-nav:before {
  content: "";
}

/*toggle despligue*/
.barramenu .menu-zabalera-blokeatu::before {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 15px !important;
  color: #c1cdd9;
  margin: -1px 0 0 -3px !important;
  padding: 0 !important;
}

.barramenu .menu-zabalera-blokeatu {
  display: none;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  right: 0;
  position: absolute;
  margin: 10px 10px 0 0;
  padding: 0px 0 0 5px !important;
  border: solid 3px #c1cdd9;
  top: 0;
  cursor: pointer;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu,
.barramenu:hover .menu-zabalera-blokeatu {
  display: block;
  line-height: 19px;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu::before {
  content: "";
}

/*toggle despliegue amaiera*/
/*dropdown menu*/
.barramenu a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta a.nav-link.dropdown-toggle::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  content: "";
  color: #fff;
  float: right;
  margin-top: 5px;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  border-right-width: 0;
  border-top-width: 0;
}

.barramenu:hover .nav-item.show a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta .nav-item.show a.nav-link.dropdown-toggle::after {
  transform: rotate(135deg);
}

.show a.nav-link.dropdown-toggle {
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu .dropdown-menu {
  position: relative !important;
  background-color: transparent;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  transform: translate3d(0px, 0px, 0px) !important;
  float: none !important;
}

.dropdown-item {
  padding: 5px 15px;
  width: 100%;
  display: block;
}

.barramenu .dropdown-menu.show {
  display: none !important;
}

.barramenu:hover .dropdown-menu.show, .barramenu.menu-zabalduta .dropdown-menu.show {
  display: block !important;
}

/*dropdown menu*/
/*barramenu zabalduta*/
.barramenu:hover, .barramenu.menu-zabalduta {
  width: 230px;
}

.barramenu.menu-zabalduta .logotipoa, .barramenu:hover .logotipoa {
  width: 180px;
  border-radius: 0;
  background-size: 100%;
  background-position: 7px;
}

.barramenu.menu-zabalduta ul.nav, .barramenu:hover ul.nav {
  margin: 0;
}

.barramenu:hover h3, .barramenu.menu-zabalduta h3 {
  display: block;
}

.barramenu:hover a, .barramenu.menu-zabalduta a {
  visibility: visible;
}

.barratop.menu-zabalduta {
  left: 230px;
}

.contenedor.body-content.menu-zabalduta {
  margin: 60px 20px 0 240px;
}

.contenedor.body-content {
  margin: 65px 20px 0 70px;
  transition: 0.3s ease all;
}

.barramenu .nav > .show > a,
.barramenu .nav > .show > a:hover,
.barramenu .nav > .show > a:focus {
  border-left-color: transparent;
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu:hover li.dropdown.show > a::after,
.barramenu.menu-zabalduta li.dropdown.show > a::after {
  transform: rotate(135deg);
}

.barramenu:hover li.dropdown > a::after,
.barramenu.menu-zabalduta li.dropdown > a::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.barramenu ul.dropdown-menu.show {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #24ab95;
  margin: 0;
}

ul.dropdown-menu a.nav-link {
  padding: 4px 0 0 28px;
}

.barramenu ul.dropdown-menu li::before {
  content: "";
  left: 10px;
  margin-top: 6px;
  position: absolute;
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 10px !important;
  margin-right: 15px;
  transition: all 0.5s;
  color: white;
}

li.dropdown.show > a {
  background: #222a31 !important;
}

.barramenu > .nav > li > a.home-nav:before {
  content: "";
}

.barramenu > .nav > li > a.ficheros-nav:before {
  content: "";
}

.barramenu > .nav > li > a.difuntos-nav:before {
  content: "";
}

.barramenu > .nav > li > a.cementerios-nav:before {
  content: "";
}

/*barramenu zabalduta amaiera*/
.beheko-minimenu {
  position: fixed;
  bottom: 0px;
  background-color: #253341;
  margin: 0;
  width: 60px;
  cursor: pointer;
  z-index: 9999;
  transition: 0.3s ease all;
  padding: 0;
}

ul.beheko-minimenu li {
  width: 230px;
}

.barramenu:hover ul.beheko-minimenu,
.barramenu.menu-zabalduta ul.beheko-minimenu {
  width: 230px;
}

.beheko-minimenu a {
  padding: 10px;
}

.beheko-minimenu a:hover {
  color: #00a8ff;
}

.conf-menu {
  display: none;
}

.conf-menu.agertu {
  display: block;
}

.barramenu:hover h3.titulo-menu-configuracion, .barramenu.menu-zabalduta h3.titulo-menu-configuracion {
  display: none;
}

.barramenu:hover h3.titulo-menu-configuracion.agertu, .barramenu.menu-zabalduta h3.titulo-menu-configuracion.agertu {
  display: block;
}

.barramenu .beheko-minimenu a:before {
  color: #73879C;
  font-size: 28px !important;
}

.barramenu .beheko-minimenu a.conf:before {
  content: "";
}

ul.beheko-minimenu li {
  list-style-type: none;
}

/*MENU LATERAL AMAIERA*/
/*USERMENU*/
.usermenu li {
  cursor: pointer;
}

.tutoriales-nav::before {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 25px !important;
  color: #CAD3DD;
  content: "";
}

.usermenu li a.nombre-usuario {
  padding: 10px !important;
}

.circulo-usuario {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #00a8ff;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin: 0 10px 0 0;
}

.dropdown-menu.dropdown-usuario {
  padding: 0;
}

.inner-usuario-nombre-cont {
  background-color: #99bcb1;
  width: 300px;
  padding: 15px;
  color: #ffffff;
}

span.inner-usuario-circulo-usuario {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #00a8ff;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin: 0 10px 0 0;
}

.dropdown-menu.dropdown-usuario.show {
  right: 0;
  left: auto;
}

.inner-usuario-tipo-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-right: solid 1px #99bcb1;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-num-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-itxura-cont {
  width: 50%;
  float: left;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-titulo {
  display: block;
  color: #9e9e9e;
  font-size: 13px;
  text-align: center;
}

.itxura-aldaketa-argia {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #ffffff 0%, #e6e6e6 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

.itxura-aldaketa-iluna {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #253341 0%, #2a3f54 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

a#Logout {
  background-color: #2a3f54;
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 90%;
  transition: all 1s;
}

li.selector-idioma > .idioma-seleccionado {
  background-color: #5cc6fd;
  padding: 5px 8px;
  margin: 0 10px 10px;
  border-radius: 50%;
  color: #ffffff;
  display: block;
}

.selector-idioma ul.dropdown-menu {
  min-width: 50px;
}

/*USERMENU AMAIERA*/
.card {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border-width: 0;
  margin: 0 0 20px;
}

.card-header {
  background-color: transparent;
  border-bottom-width: 0;
}

.plegarpanel {
  position: absolute;
  right: 25px;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #2a3f54;
  border-left: solid 3px #2a3f54;
  transform: rotate(135deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  cursor: pointer;
}

.card.plegado {
  height: 60px;
  overflow: hidden;
}

.card.plegado .plegarpanel {
  transform: rotate(-45deg);
}

/*GRID*/
.k-grid {
  /*  font-size: 12px;*/
  font-size: 14px;
}

.k-grid.grid-font-10 td,
.k-grid.grid-font-10 th {
  font-size: 12px !important;
}

.modal-body .k-grid .k-group-col,
.modal-body .k-grid .k-hierarchy-col {
  width: 3px !important;
}

kendo-grid.k-widget.k-grid {
  border-width: 0;
  padding-bottom: 5px;
}

.k-pager-numbers .k-state-selected {
  z-index: 0;
}

kendo-grid.k-widget.k-grid {
  border-width: 0;
  padding-bottom: 5px;
}

.k-grid-header,
kendo-grid-toolbar.k-grid-toolbar.k-header {
  background-color: transparent;
}

.k-grid td,
.k-grid th.k-header.k-filterable {
  border-width: 0;
  cursor: pointer;
}

.k-grid th.k-header.k-filterable span.k-link {
  font-weight: bold;
}

.k-grid-table-wrap tr {
  background-color: rgba(0, 0, 0, 0.04);
}

.k-grid-table-wrap tr.k-alt {
  background-color: initial;
}

.k-grid-aria-root .k-grid-aria-root tr th {
  background: #8ad2d2;
}

.k-grid-aria-root tr:hover td.k-hierarchy-cell .k-grid-aria-root {
  background: #8ad2d2;
}

td.k-hierarchy-cell {
  background: #8ad2d2;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover,
.k-pager-numbers .k-link.k-state-selected {
  color: #18d6b0;
  background-color: rgba(24, 214, 176, 0.13);
}

.k-pager-numbers .k-link {
  color: #18d6b0;
}

/*scroll bereziak grid*/
.k-grid-content::-webkit-scrollbar-track {
  background: #ffffff;
}

/*scroll bereziak grid amaiera*/
/*GRID amaiera*/
/*ENCUESTAS*/
.bloque-encuesta {
  width: 250px;
  text-align: center;
  cursor: pointer;
}

.bloque-encuesta img {
  width: auto;
  max-height: 125px;
}

.bloque-encuesta .titulo-encuesta {
  line-height: 17px;
  margin: 10px 0 10px 0;
  display: block;
  height: 35px;
}

.bloque-encuesta .fecha-encuesta {
  font-size: 13px;
  color: #00a8ff;
}

.bloque-encuesta .card {
  min-height: 260px;
}

.votado {
  font-size: 13px;
  color: #45b39c;
}

/*ENCUESTAS AMAIERA*/
/*VOTACIÓN*/
.card-votacion {
  width: 100%;
  max-width: 500px;
}

.imagen-votacion {
  text-align: center;
}

.imagen-votacion img {
  width: auto;
  max-height: 400px;
  margin: 0 auto;
  max-width: 100%;
}

.opciones-puntos {
  margin: 0 0 15px 0;
}

.botones-puntos button:first-child {
  margin-left: 0;
}

.botones-puntos button:last-child {
  margin-right: 0;
}

.botones-puntos button {
  width: 80px;
  margin: 10px;
}

.botones-puntos button {
  width: 80px;
  margin: 10px;
  border: solid 1px #22C4C4;
  text-align: center;
  font-size: 18px;
  color: #22C4C4;
  padding: 3px;
  background-color: transparent;
}

/*.botones-puntos button.k-state-selected {
  background: #22C4C4 !important;
  color: #22C4C4 !important;
  border-color: #22C4C4 !important;
}*/
/*
.botones-puntos .k-button-group .k-button:active,
.botones-puntos .k-button-group .k-button.k-state-selected {
  color: #ffffff;
  background-color: #22C4C4;
  background-image: linear-gradient(rgba(255, 138, 88, 0.2), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 6%);
  border: solid 1px #22C4C4;
}*/
/*
    .botones-puntos .k-button-group .k-button:active,
.botones-puntos .k-button-group .k-button.k-state-selected.seleccionado {
  color: #ffffff;
  background-color: #22C4C4;
  background-image: linear-gradient(rgba(255, 138, 88, 0.2), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 6%);
  border: solid 1px #22C4C4;
}

.botones-puntos .k-button-group .k-button.k-state-selected.normal {
  color: #22C4C4;
  background-color: transparent;
  background-image: linear-gradient(rgba(255, 138, 88, 0), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 0%);
  border: solid 1px #22C4C4;
}

.botones-puntos .k-button-group .k-button.k-state-selected.seleccionado[aria-pressed="true"] {
  background-color: yellow !important;
}

.botones-puntos .k-button-group .k-button.k-state-selected.normal[aria-pressed="true"] {
  background-color: transparent !important;
}




.botones-puntos .k-button-group .k-button[aria-pressed="true"] {
  background-color: transparent !important;
  background-image: linear-gradient(rgba(255, 138, 88, 0), rgba(255, 138, 88, 0));
  color: #22C4C4 !important;
}

.botones-puntos .k-button-group .k-button.clickado[aria-pressed="true"] {
  color: #ffffff !important;
  background-color: #22C4C4 !important;
  background-image: linear-gradient(rgba(255, 138, 88, 0.2), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 6%);
  border: solid 1px #22C4C4;
}
*/
.botones-puntos button.seleccionado {
  background-color: #22C4C4;
  color: #ffffff;
}

.botones-puntos button.normal {
  background-color: #ffffff;
}

.villabona-testua {
  margin: 15px 0 15px 0;
}

.villabona-testua p,
.villabona-testua li {
  font-size: 20px;
  color: #29c4c4;
  margin: 0 0 5px 0;
  line-height: 25px;
}

.villabona-testua p.adi {
  color: #f16a72;
  font-size: 25px;
}

.villabona-testua span.testuko-puntuak {
  width: 80px;
  margin: 4px;
  border: solid 1px #22C4C4;
  text-align: center;
  font-size: 18px;
  color: #22C4C4;
  padding: 3px;
  background-color: transparent;
  display: inline-block;
}

table.tabla-datos-encuesta {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}

table.tabla-datos-encuesta th {
  font-size: 12px;
}

table.tabla-datos-encuesta td,
table.tabla-datos-encuesta th {
  padding-left: 10px;
}

td.celda-3puntos,
td.celda-puntos,
td.celda-porcentaje {
  text-align: right;
}

th.titulo-tabla-3puntos,
th.titulo-tabla-puntos,
th.titulo-tabla-porcentaje {
  text-align: right;
  padding-right: 5px;
}

table.tabla-datos-encuesta td.celda-porcentajes {
  text-align: right;
}

table.tabla-datos-encuesta tr {
  background: #f6f6f6;
}

.celda-opcion,
.titulo-tabla-opcion {
  width: 65%;
}

.celda-3puntos,
th.titulo-tabla-3puntos {
  width: 15%;
}

.celda-puntos,
.titulo-tabla-puntos {
  width: 10%;
}

.celda-porcentaje,
.titulo-tabla-porcentaje {
  width: 10%;
}

/*
.opciones {
  display: flex;
  flex-direction: column;
}

.opcion-cont {
  background-color: #F6F6F6;
  margin: 0 0 10px 0;
  cursor: pointer;
}

.opcion-cont-inner {
  display: flex;
  align-items: center;
}

.puntos {
  height: 40px;
  width: 40px;
  background: #22C4C4;
  text-align: center;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  color: #ffffff;
}

.seleccionado {
  color: lightsteelblue;
}

.opcion-cont-inner.seleccionado:after {
  display: flex;
  content: "x";
  background-color: red;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
}

.opcion-nombre {
  height: 50px;
  height: 50px;
  align-items: center;
  display: flex;
}

.seleccionado .opcion-nombre {
  width: calc(100% - 100px);
}

.normal .opcion-nombre {
  width: calc(100% - 50px);
  padding-left: 10px;
}

.normal .puntos {
  display: none;
}
    */
/*VOTACIÓN FIN*/
/*solo móvil*/
@media (max-width: 768px) {
  .barramenu {
    height: 56px;
    overflow: hidden;
  }

  .barramenu:hover, .barramenu.menu-zabalduta {
    width: 230px;
    height: 100vh;
    overflow-y: auto;
  }

  .menu-zabalera-blokeatu {
    display: none !important;
  }

  .contenedor.body-content {
    margin: 70px 0 0 0 !important;
    transition: 0.3s ease all;
  }

  .bloque-encuesta {
    width: 100%;
    margin: 0 auto !important;
  }
}
/*tablet*/
/*escritorio*/